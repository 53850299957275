import React from 'react'
import ToolCard from './ToolCard'
import data_management from '../../assets/data_management.jpg'
import city_plan from '../../assets/cityplan.jpg'
import Grid from '@mui/material/Grid'

const tool_details = [
  {
    title: 'DataLogue',
    description: 'Create, update and delete files to use on the DataLogue platform.',
    image_name: data_management,
    url: '/datalogue',
    required_privilege: 'datalogue',
  },
  {
    title: 'CityPlan',
    description: 'A collection of Apps aimed at providing city planning support. ',
    image_name: city_plan,
    url: null,
    required_privilege: null,
    route: 'city_plan',
  },
]

export default function Landing(props) {
  const { privileges } = props

  return (
    <Grid container spacing={5} style={{ padding: 60 }}>
      {tool_details.map((tool_detail, index) => {
        return (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={index}>
            <ToolCard
              key={index}
              title={tool_detail.title}
              description={tool_detail.description}
              image_name={tool_detail.image_name}
              href={tool_detail.url}
              enabled={tool_detail.required_privilege ? privileges.includes(tool_detail.required_privilege) : true}
              route={tool_detail.route}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}
