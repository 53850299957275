import * as React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import BuildIcon from '@mui/icons-material/Build'
import CityPlan from './CityPlan'
import ChangePassowrd from './ChangePassword'
import { Route, Switch, Link } from 'react-router-dom'
import PasswordIcon from '@mui/icons-material/Password'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'
import UserManagement from '../UserManagement/Main'
import background_blur from '../../assets/background_blur.jpg'
import AccountCircle from '@mui/icons-material/AccountCircle'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import Landing from './Landing'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import { useHistory } from 'react-router-dom'

const drawerWidth = 240

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  backgroundImage: `url(${background_blur})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  minHeight: '100vh',
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

export default function PersistentDrawerLeft({ match, privileges }) {
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const [anchor, setAnchor] = React.useState(null)
  const isMenuOpen = Boolean(anchor)
  const history = useHistory()

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleProfileMenuOpen = (event) => {
    setAnchor(event.currentTarget)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleMenuClose = () => {
    setAnchor(null)
  }

  const logout = () => {
    localStorage.removeItem('token')
    handleMenuClose()
    history.push('/')
  }

  const go_home = () => {
    handleMenuClose()
    history.push('/authorised/landing')
  }

  const list_items = [
    {
      key: 'landing',
      url: '/landing',
      text: 'Landing',
      icon: <BuildIcon />,
      privilege: 'login',
    },
    {
      key: 'cityplan',
      url: '/city_plan',
      text: 'City Plan',
      icon: <LocationCityIcon />,
      privilege: 'login',
    },
    {
      key: 'password',
      url: '/password',
      text: 'Password',
      icon: <PasswordIcon />,
      privilege: 'login',
    },
    {
      key: 'user_management',
      url: '/user_management',
      text: 'User Management',
      icon: <SupervisedUserCircleIcon />,
      privilege: 'user_admin',
    },
  ]
  const logout_tab = (
    <Menu
      anchorEl={anchor}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={go_home}>Home</MenuItem>
      <MenuItem onClick={logout}>Logout</MenuItem>
    </Menu>
  )

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            CSIR Smart Places
          </Typography>
          <IconButton edge="end" onClick={handleProfileMenuOpen} color="inherit">
            <AccountCircle />
          </IconButton>
        </Toolbar>
        {logout_tab}
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {list_items.map((list_item, index) =>
            privileges.includes(list_item.privilege) ? (
              <ListItem button key={index} component={Link} to={match.url + list_item.url}>
                <ListItemIcon>{list_item.icon}</ListItemIcon>
                <ListItemText primary={list_item.text} />
              </ListItem>
            ) : null
          )}
        </List>
        <Divider />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Switch>
          <Route path={match.url + '/landing'} render={(props) => <Landing privileges={privileges} {...props} />} />
          <Route path={match.url + '/city_plan'} render={(props) => <CityPlan privileges={privileges} {...props} />} />
          <Route path={match.url + '/password'} render={(props) => <ChangePassowrd {...props} />} />
          <Route path={match.url + '/user_management'} render={(props) => <UserManagement {...props} />} />
        </Switch>
      </Main>
    </Box>
  )
}
