import { useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import axios from 'axios'
import { Typography } from '@mui/material'
import Fade from '@mui/material/Fade'
const config = require('../../config.json')

const LoginForm = (props) => {
  const { set_messages } = props

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [code, setCode] = useState('')
  const [show_code_form, set_show_code_form] = useState(false)

  const changeEmail = (e) => {
    setEmail(e.target.value)
  }
  const changePassword = (e) => {
    setPassword(e.target.value)
  }
  const changeCode = (e) => {
    setCode(e.target.value)
  }

  const onSubmitCode = async (event) => {
    event.preventDefault()
    set_messages([])
    try {
      await axios.post(config.api_base_url + '/user_management/password_reset/create', {
        email: email,
      })
      set_messages([{ severity: 'success', message: 'Code sent to email address' }])
      set_show_code_form(true)
    } catch (error) {
      if (error.response) {
        set_messages(
          error.response.data.errors.map((error) => {
            return { severity: 'error', message: error.msg }
          })
        )
      } else {
        set_messages([{ severity: 'error', message: 'There was a problem contacting the server.' }])
      }
    }
  }

  const onSubmitPassword = async (event) => {
    event.preventDefault()
    set_messages([])
    try {
      await axios.post(config.api_base_url + '/user_management/password_reset/submit', {
        user_password: password,
        code: code,
      })
      set_messages([{ severity: 'success', message: 'Password changed successfully' }])
    } catch (error) {
      if (error.response) {
        set_messages(
          error.response.data.errors.map((error) => {
            return { severity: 'error', message: error.msg }
          })
        )
      } else {
        set_messages([{ severity: 'error', message: 'There was a problem contacting the server.' }])
      }
    }
  }

  return (
    <div>
      <div>
        <Typography variant="h6" align="center" color="secondary" marginTop={2}>
          Reset Password
        </Typography>
        <TextField
          margin="dense"
          variant="standard"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          onChange={changeEmail}
          value={email}
          autoFocus
        />
        <Button
          onClick={onSubmitCode}
          fullWidth
          variant="contained"
          color="primary"
          style={{ marginTop: '10px' }}
          value="Submit"
        >
          Request Code
        </Button>
        <Typography
          style={{ cursor: 'pointer' }}
          variant="caption"
          color="primary"
          onClick={() => {
            set_show_code_form((show_code_form) => !show_code_form)
          }}
        >
          already have a code?
        </Typography>
      </div>
      <Fade in={show_code_form} unmountOnExit={true}>
        <div>
          <TextField
            margin="dense"
            variant="standard"
            required
            fullWidth
            id="password"
            type="password"
            label="New Password"
            name="password"
            onChange={changePassword}
            value={password}
            autoFocus
          />
          <TextField
            margin="dense"
            variant="standard"
            required
            fullWidth
            id="code"
            label="Email Code"
            name="code"
            onChange={changeCode}
            value={code}
            autoFocus
          />
          <Button
            onClick={onSubmitPassword}
            fullWidth
            variant="contained"
            color="primary"
            style={{ marginTop: '10px' }}
            value="Submit"
          >
            Set Password
          </Button>
        </div>
      </Fade>
    </div>
  )
}

export default LoginForm
