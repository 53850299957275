import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { makeStyles, useTheme } from "@mui/styles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableFooter from "@mui/material/TableFooter"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import TextField from "@mui/material/TextField"
import IconButton from "@mui/material/IconButton"
import FirstPageIcon from "@mui/icons-material/FirstPage"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import LastPageIcon from "@mui/icons-material/LastPage"
import TableHead from "@mui/material/TableHead"
import DeleteIcon from "@mui/icons-material/Delete"
import Button from "@mui/material/Button"
import EditIcon from "@mui/icons-material/Edit"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Chip from "@mui/material/Chip"
import InputLabel from "@mui/material/InputLabel"
import axios from "axios"
import Box from "@mui/material/Box"
import Alert from "@mui/material/Alert"

const config = require("../../config.json")

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
})

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}))

function TablePaginationActions(props) {
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}

function ConfirmDelete(props) {
  const {
    openDialog,
    setOpenConfirmDelete,
    currentRow,
    set_roles,
    setViewOnDelete,
  } = props
  const { platform_role_pk } = currentRow
  const [error, set_error] = React.useState(null)

  const handleClose = () => {
    set_error(undefined)
    props.setOpenConfirmDelete(false)
  }

  const delete_role = async () => {
    try {
      const { data } = await axios.delete(
        config.api_base_url + `/user_management/role/${platform_role_pk}`
      )
      set_roles((current_roles) => {
        let updated_roles = [...current_roles]
        updated_roles = updated_roles.filter((table_row) => {
          return table_row.platform_role_pk !== data.platform_role_pk
        })
        return updated_roles
      })
      setViewOnDelete()
      setOpenConfirmDelete(false)
    } catch (error) {
      set_error("Role could not be deleted.")
    }
  }

  return (
    <Dialog open={openDialog} onClose={handleClose}>
      <DialogTitle id="form-dialog-title">Delete Role</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this entry?
        </DialogContentText>

        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Typography>Name</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{currentRow["platform_role_name"]}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Description</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{currentRow["platform_role_description"]}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Privileges</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              {currentRow.platform_privileges
                ? currentRow.platform_privileges
                    .map((privilege) => privilege.privilege_name)
                    .join(", ")
                : null}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            delete_role()
          }}
          color="primary"
        >
          Delete Entry
        </Button>
      </DialogActions>
      {error ? <Alert severity="error">{error}</Alert> : null}
    </Dialog>
  )
}

function EditDialog(props) {
  const {
    openDialog,
    setOpenEditDialog,
    currentRow,
    set_roles,
    setCurrentRow,
  } = props
  const [privileges, set_privileges] = useState([])

  useEffect(() => {
    axios
      .get(config.api_base_url + "/user_management/privilege/all")
      .then(({ data }) => {
        set_privileges(data)
      })
  }, [])

  const classes = useStyles()

  const handleClose = () => {
    setOpenEditDialog(false)
  }

  const edit_role = async () => {
    try {
      const { data } = await axios.put(
        config.api_base_url + "/user_management/role",
        currentRow
      )

      set_roles((current_roles) => {
        let updated_roles = [...current_roles]
        const elementsIndex = updated_roles.findIndex((role) => {
          return role.platform_role_pk === data.platform_role_pk
        })
        updated_roles[elementsIndex] = data
        return updated_roles
      })

      handleClose()
    } catch (error) {
      console.log(error)
    }
  }

  const handleValueChange = (event) => {
    const name = event.target.name
    let new_value = event.target.value
    if (name === "platform_privileges") {
      new_value = privileges.filter((privilege) =>
        new_value.includes(privilege.privilege_pk)
      )
    }
    var new_state = {
      ...currentRow,
      [name]: new_value,
    }
    setCurrentRow(new_state)
  }

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Update Role</DialogTitle>
      <DialogContent>
        <DialogContentText>Update role information.</DialogContentText>

        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          name="platform_role_name"
          label="Role Name"
          type="text"
          value={currentRow.platform_role_name}
          onChange={handleValueChange}
        />

        <TextField
          margin="dense"
          variant="standard"
          name="platform_role_description"
          label="Role Description"
          type="text"
          multiline
          fullWidth
          value={currentRow.platform_role_description}
          onChange={handleValueChange}
        />

        <FormControl fullWidth margin="dense">
          <InputLabel>Privileges</InputLabel>
          <Select
            variant="standard"
            multiple
            value={
              currentRow.platform_privileges
                ? currentRow.platform_privileges.map(
                    (platform_privileg) => platform_privileg.privilege_pk
                  )
                : []
            }
            onChange={handleValueChange}
            name="platform_privileges"
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((privilege_pk) => {
                  const privilege = privileges.find((privilege) => {
                    return privilege.privilege_pk === privilege_pk
                  })
                  return (
                    <Chip
                      key={privilege_pk}
                      label={privilege.privilege_name}
                      className={classes.chip}
                    />
                  )
                })}
              </div>
            )}
          >
            {privileges.map((privilege) => (
              <MenuItem
                key={privilege.privilege_pk}
                value={privilege.privilege_pk}
              >
                {privilege.privilege_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={edit_role} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function NewDialog(props) {
  const { openNewDialog, setOpenNewDialog, set_roles, setViewOnCreate } = props

  const [privileges, set_privileges] = React.useState([])

  useEffect(() => {
    axios
      .get(config.api_base_url + "/user_management/privilege/all")
      .then(({ data }) => {
        set_privileges(data)
      })
  }, [])

  const initial_form_data = {
    platform_role_name: "",
    platform_role_description: "",
    platform_privileges: [],
  }
  const [formData, setFormData] = React.useState(initial_form_data)

  const handleValueChange = (event) => {
    const name = event.target.name
    let new_value = event.target.value
    if (name === "platform_privileges") {
      new_value = privileges.filter((privilege) =>
        new_value.includes(privilege.privilege_pk)
      )
    }
    var new_state = {
      ...formData,
      [name]: new_value,
    }
    setFormData(new_state)
  }

  const create_role = async () => {
    try {
      const { data } = await axios.post(
        config.api_base_url + `/user_management/role`,
        formData
      )

      set_roles((current_roles) => {
        let updated_roles = [...current_roles]
        updated_roles.push(data)
        return updated_roles
      })

      setFormData(initial_form_data)
      setOpenNewDialog(false)
      setViewOnCreate()
    } catch (error) {
      console.log(error)
    }
  }

  const handleClose = () => {
    setOpenNewDialog(false)
  }

  return (
    <Dialog
      open={openNewDialog}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">New Role</DialogTitle>
      <DialogContent>
        <DialogContentText>Create a new Role Entry.</DialogContentText>

        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          name="platform_role_name"
          label="Role Name"
          type="text"
          value={formData.platform_role_name}
          onChange={handleValueChange}
        />

        <TextField
          margin="dense"
          variant="standard"
          name="platform_role_description"
          label="Role Description"
          type="text"
          multiline
          fullWidth
          value={formData.platform_role_description}
          onChange={handleValueChange}
        />

        <TextField
          select
          label="Privileges"
          variant="standard"
          fullWidth
          margin="dense"
          SelectProps={{
            multiple: true,
            value: formData.platform_privileges
              ? formData.platform_privileges.map(
                  (privilege) => privilege.privilege_pk
                )
              : [],
            onChange: handleValueChange,
          }}
          name="platform_privileges"
        >
          {privileges.map((privilege) => (
            <MenuItem
              key={privilege.privilege_pk}
              value={privilege.privilege_pk}
            >
              {privilege.privilege_name}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={create_role} color="primary">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default function RolesPrivilege() {
  const classes = useStyles2()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false)
  const [openEditDialog, setOpenEditDialog] = React.useState(false)
  const [openNewDialog, setOpenNewDialog] = React.useState(false)
  const [currentRow, setCurrentRow] = React.useState({})
  const [roles, set_roles] = React.useState([])

  useEffect(() => {
    axios
      .get(config.api_base_url + "/user_management/role/all")
      .then(({ data }) => {
        set_roles(data)
      })
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value))
    setPage(0)
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, roles.length - page * rowsPerPage)

  const setViewOnCreate = () => {
    const last_page = Math.ceil(roles.length / rowsPerPage) - 1
    setPage(last_page)
  }

  const setViewOnDelete = () => {
    const new_number_rows = roles.length - 1
    const num_pages = Math.ceil(roles.length / rowsPerPage) - 1
    if (new_number_rows % rowsPerPage === 0 && num_pages === page) {
      setPage(Math.max(num_pages - 1, 0))
    }
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Name</b>
              </TableCell>
              <TableCell>
                <b>Description</b>
              </TableCell>
              <TableCell>
                <b>Privileges</b>
              </TableCell>
              <TableCell align="center">
                <b>Update</b>
              </TableCell>
              <TableCell align="center">
                <b>Delete</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? roles.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : roles
            ).map((role, index) => {
              const privilege_string = role.platform_privileges
                .map((privilege) => privilege.privilege_name)
                .join(", ")
              return (
                <TableRow key={index}>
                  <TableCell>{role.platform_role_name}</TableCell>
                  <TableCell>{role.platform_role_description}</TableCell>
                  <TableCell>{privilege_string}</TableCell>
                  <TableCell align="center" padding="none">
                    <Button
                      onClick={() => {
                        setCurrentRow(role)
                        setOpenEditDialog(true)
                      }}
                    >
                      <EditIcon />
                    </Button>
                  </TableCell>
                  <TableCell align="center" padding="none">
                    <Button
                      onClick={() => {
                        setCurrentRow(role)
                        setOpenConfirmDelete(true)
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 52 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    setOpenNewDialog(true)
                  }}
                >
                  Create Role
                </Button>
              </TableCell>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                colSpan={8}
                count={roles.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <NewDialog
        openNewDialog={openNewDialog}
        setOpenNewDialog={setOpenNewDialog}
        set_roles={set_roles}
        setViewOnCreate={setViewOnCreate}
      />
      <ConfirmDelete
        openDialog={openConfirmDelete}
        setOpenConfirmDelete={setOpenConfirmDelete}
        currentRow={currentRow}
        set_roles={set_roles}
        setViewOnDelete={setViewOnDelete}
      />
      <EditDialog
        openDialog={openEditDialog}
        setOpenEditDialog={setOpenEditDialog}
        currentRow={currentRow}
        set_roles={set_roles}
        setCurrentRow={setCurrentRow}
      />
    </div>
  )
}
