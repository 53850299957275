import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { makeStyles, useTheme } from "@mui/styles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableFooter from "@mui/material/TableFooter"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import IconButton from "@mui/material/IconButton"
import FirstPageIcon from "@mui/icons-material/FirstPage"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import LastPageIcon from "@mui/icons-material/LastPage"
import TableHead from "@mui/material/TableHead"
import Button from "@mui/material/Button"
import EditIcon from "@mui/icons-material/Edit"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Chip from "@mui/material/Chip"
import InputLabel from "@mui/material/InputLabel"
import axios from "axios"
import Box from "@mui/material/Box"
import { TextField } from "@mui/material"
import Alert from "@mui/material/Alert"

const config = require("../../config.json")

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
})

function TablePaginationActions(props) {
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}))

function EditDialog(props) {
  const {
    openDialog,
    setOpenEditDialog,
    currentRow,
    setCurrentRow,
    users,
    set_users,
  } = props
  const classes = useStyles()
  const [roles, set_roles] = React.useState([])
  const [municipalities, set_municipalities] = React.useState([])
  const [messages, set_messages] = React.useState([])
  const [agencies, set_agencies] = React.useState([])

  useEffect(() => {
    axios
      .get(config.api_base_url + "/user_management/gtfs/all")
      .then(({ data }) => {
        set_agencies(data)
      })
  }, [])

  useEffect(() => {
    axios
      .get(config.api_base_url + "/user_management/role/all")
      .then(({ data }) => {
        set_roles(data)
      })
  }, [])

  useEffect(() => {
    axios
      .get(config.api_base_url + "/user_management/municipality/all")
      .then(({ data }) => {
        set_municipalities(data)
      })
  }, [])

  const handleClose = () => {
    setOpenEditDialog(false)
  }

  const edit_user_approvers = async () => {
    try {
      const response = await axios.put(
        config.api_base_url + "/user_management/approver",
        currentRow
      )
      set_users((current_users) => {
        let updated_users = [...current_users]
        const elementsIndex = updated_users.findIndex(
          (table_row) => table_row.user_pk === response.data.user_pk
        )
        updated_users[elementsIndex] = response.data
        return updated_users
      })
      set_messages([
        {
          severity: "success",
          message: "User approvers updated",
        },
      ])
    } catch (error) {
      set_messages([
        {
          severity: "error",
          message: "User approvers could not be updated",
        },
      ])
      console.log(error)
    }
  }

  const edit_user_roles = async () => {
    try {
      await axios.put(
        config.api_base_url + `/user_management/role/link`,
        currentRow
      )
      set_users((current_users) => {
        let updated_users = [...current_users]
        const elementsIndex = updated_users.findIndex(
          (table_row) => table_row.user_pk === currentRow.user_pk
        )
        updated_users[elementsIndex] = currentRow
        return updated_users
      })
      set_messages([
        {
          severity: "success",
          message: "User roles updated",
        },
      ])
    } catch (error) {
      set_messages([
        {
          severity: "error",
          message: "Could not update user roles.",
        },
      ])
      console.log(error)
    }
  }

  const update_municipality = async () => {
    try {
      const { user_pk } = currentRow
      const { municipality_pk } = currentRow.municipality
      await axios.put(
        config.api_base_url + `/user_management/municipality/link`,
        {
          user_pk,
          municipality_pk,
        }
      )
      set_users((current_users) => {
        let updated_users = [...current_users]
        const elementsIndex = updated_users.findIndex(
          (table_row) => table_row.user_pk === currentRow.user_pk
        )
        updated_users[elementsIndex] = currentRow
        return updated_users
      })
      set_messages([
        {
          severity: "success",
          message: "User municipality updated.",
        },
      ])
    } catch (error) {
      set_messages([
        {
          severity: "error",
          message: "User municipality could not be updated.",
        },
      ])
      console.log(error)
    }
  }

  const edit_user_feeds = async () => {
    try {
      await axios.put(config.api_base_url + "/user_management/gtfs", currentRow)
      set_users((current_users) => {
        let updated_users = [...current_users]
        const elementsIndex = updated_users.findIndex(
          (table_row) => table_row.user_pk === currentRow.user_pk
        )
        updated_users[elementsIndex] = currentRow
        return updated_users
      })
      set_messages([
        {
          severity: "success",
          message: "User feeds updated.",
        },
      ])
    } catch (error) {
      set_messages([
        {
          severity: "error",
          message: "User feeds could not be updated.",
        },
      ])
      console.log(error)
    }
  }

  const handle_role_change = (event) => {
    const name = event.target.name
    const selected_pks = event.target.value
    let selected_roles = roles.filter((role) =>
      selected_pks.includes(role.platform_role_pk)
    )
    var new_state = {
      ...currentRow,
    }
    new_state[name] = selected_roles
    setCurrentRow(new_state)
  }

  const handle_municipality_change = (event) => {
    let new_value = event.target.value
    var new_state = {
      ...currentRow,
    }
    const municipality = municipalities.find(
      (municipality) => municipality.municipality_pk === new_value
    )
    new_state.municipality = municipality
    setCurrentRow(new_state)
  }

  const handle_approver_chage = (event) => {
    const name = event.target.name
    let new_value = event.target.value
    new_value = users.filter((user) => new_value.includes(user.user_pk))
    var new_state = {
      ...currentRow,
      [name]: new_value,
    }
    setCurrentRow(new_state)
  }

  const handle_gtfs_change = (event) => {
    const name = event.target.name
    let new_value = event.target.value
    if (name === "AgenciesPerUsers") {
      new_value = agencies.filter((agency) =>
        new_value.includes(agency.agency_pk)
      )
    }
    var new_state = {
      ...currentRow,
      [name]: new_value,
    }
    setCurrentRow(new_state)
  }

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Update User</DialogTitle>
      <DialogContent>
        <DialogContentText gutterBottom>
          User: {currentRow.user_name}
        </DialogContentText>
        <FormControl fullWidth margin="dense">
          <InputLabel>Roles</InputLabel>
          <Select
            variant="standard"
            multiple
            value={
              currentRow.platform_roles
                ? currentRow.platform_roles.map(
                    (platform_role) => platform_role.platform_role_pk
                  )
                : []
            }
            onChange={handle_role_change}
            name="platform_roles"
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((role_pk) => {
                  const role = roles.find((role) => {
                    return role.platform_role_pk === role_pk
                  })
                  return (
                    <Chip
                      key={role_pk}
                      label={role.platform_role_name}
                      className={classes.chip}
                    />
                  )
                })}
              </div>
            )}
          >
            {roles.map((role) => (
              <MenuItem
                key={role.platform_role_pk}
                value={role.platform_role_pk}
                data={role}
              >
                {role.platform_role_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button fullWidth onClick={edit_user_roles}>
          Update Roles
        </Button>
        <TextField
          label="Municipality"
          select
          fullWidth
          variant="standard"
          value={
            currentRow.municipality
              ? currentRow.municipality.municipality_pk
              : ""
          }
          onChange={handle_municipality_change}
        >
          {municipalities.map((municipality) => {
            return (
              <MenuItem
                key={municipality.municipality_pk}
                value={municipality.municipality_pk}
              >
                {municipality.municipality_name}
              </MenuItem>
            )
          })}
        </TextField>
        <Button fullWidth onClick={update_municipality}>
          Update Municipality
        </Button>
        <TextField
          fullWidth
          select
          margin="dense"
          label="Approver Names"
          multiple
          variant="standard"
          name="approvers"
          SelectProps={{
            multiple: true,
            value: currentRow.approvers
              ? currentRow.approvers.map((approver) => approver.user_pk)
              : [],
            onChange: handle_approver_chage,
          }}
        >
          {users.map((user) => (
            <MenuItem key={user.user_pk} value={user.user_pk}>
              {user.user_name}
            </MenuItem>
          ))}
        </TextField>
        <Button fullWidth onClick={edit_user_approvers}>
          Update Approvers
        </Button>
        <FormControl fullWidth margin="dense">
          <InputLabel> Feeds</InputLabel>
          <Select
            variant="standard"
            multiple
            value={
              currentRow.AgenciesPerUsers
                ? currentRow.AgenciesPerUsers.map((agency) => agency.agency_pk)
                : []
            }
            onChange={handle_gtfs_change}
            name="AgenciesPerUsers"
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((agency_pk) => {
                  const agency = agencies.find((_agency) => {
                    return _agency.agency_pk === agency_pk
                  })
                  return (
                    <Chip
                      key={agency_pk}
                      label={agency.agency_details.agency_name}
                      className={classes.chip}
                    />
                  )
                })}
              </div>
            )}
          >
            {agencies.map((_agency) => (
              <MenuItem key={_agency.agency_pk} value={_agency.agency_pk}>
                {_agency.agency_details.agency_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button fullWidth onClick={edit_user_feeds}>
          Update Approvers
        </Button>
        {messages.map((message, index) => {
          return (
            <Alert
              key={index}
              severity={message.severity}
              style={{ margin: "2px" }}
            >
              {message.message}
            </Alert>
          )
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default function RolesPerUser(props) {
  const classes = useStyles2()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [openEditDialog, setOpenEditDialog] = React.useState(false)
  const [currentRow, setCurrentRow] = React.useState({})
  const [users, set_users] = React.useState([])

  useEffect(() => {
    axios
      .get(config.api_base_url + "/user_management/user/all")
      .then(({ data }) => {
        set_users(data)
      })
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value))
    setPage(0)
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage)

  return (
    <div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Name</b>
              </TableCell>
              <TableCell>
                <b>Email</b>
              </TableCell>
              <TableCell>
                <b>Institution</b>
              </TableCell>
              <TableCell>
                <b>Department</b>
              </TableCell>
              <TableCell>
                <b>Municipality</b>
              </TableCell>
              <TableCell>
                <b>Roles</b>
              </TableCell>
              <TableCell align="center">
                <b>Update</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? users.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : users
            ).map((user, index) => (
              <TableRow key={index}>
                <TableCell>{user.user_name}</TableCell>
                <TableCell>{user.user_email}</TableCell>
                <TableCell>{user.institution.institution_name}</TableCell>
                <TableCell>{user.institution.department_name}</TableCell>
                <TableCell>
                  {user.municipality
                    ? user.municipality.municipality_name
                    : null}
                </TableCell>
                <TableCell>
                  {user.platform_roles
                    .map((platform_roles) => {
                      return platform_roles.platform_role_name
                    })
                    .join(", ")}
                </TableCell>
                <TableCell align="center" padding="none">
                  <Button
                    onClick={() => {
                      setCurrentRow(user)
                      setOpenEditDialog(true)
                    }}
                  >
                    <EditIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 52 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                colSpan={8}
                count={users.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <EditDialog
        openDialog={openEditDialog}
        setOpenEditDialog={setOpenEditDialog}
        currentRow={currentRow}
        users={users}
        set_users={set_users}
        setCurrentRow={setCurrentRow}
      />
    </div>
  )
}
