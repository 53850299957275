import { useState } from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import axios from "axios"
import setHeaders from "../../utils/setHeaders"
import Typography from "@mui/material/Typography"
import { useHistory } from "react-router-dom"
const config = require("../../config.json")

const LoginForm = (props) => {
    const { set_action, set_messages } = props

    const history = useHistory()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')


    const changeEmail = (e) => {
        setEmail(e.target.value)
    }
    const changePassword = (e) => {
        setPassword(e.target.value)
    }

    const onSubmit = async (event) => {
        event.preventDefault()
        set_messages([])
        try {
            const response = await axios.post(
                config.api_base_url + '/user_management/user/login',
                {
                    email: email,
                    password: password,
                }
            )
            localStorage.setItem('token', response.data.token)
            setHeaders(localStorage.token)
            history.push('/authorised/landing')
        } catch (error) {
            if (error.response) {
                set_messages(
                    error.response.data.errors.map((error) => {
                        return { severity: 'error', message: error.msg }
                    })
                )
            } else {
                set_messages([
                    {
                        severity: 'error',
                        message: 'There was a problem contacting the server.',
                    },
                ])
            }
        }
    }
    return (
        <form
            noValidate
            onSubmit={onSubmit}>
            <Typography
                variant='h6'
                align='center'
                color='secondary'
                marginTop={2}>
                Login
            </Typography>
            <TextField
                margin='dense'
                variant='standard'
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                onChange={changeEmail}
                value={email}
                autoFocus
            />
            <TextField
                margin='dense'
                variant='standard'
                required
                fullWidth
                name='password'
                label='Password'
                type='password'
                id='password'
                onChange={changePassword}
                value={password}
            />
            <Typography
                style={{ cursor: 'pointer' }}
                variant='caption'
                color='primary'
                onClick={() => {
                    set_action('reset')
                }}>
                forgot password
            </Typography>

            <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                style={{ marginTop: '10px' }}
                value='Submit'>
                Sign In
            </Button>
        </form>
    )
}

export default LoginForm
