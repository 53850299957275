import { useState } from "react"
import PropTypes from "prop-types"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"
import UserActivity from "./UserActivity"
import InstitutionTable from "./InstitutionTable"
import MunicipalitiesTable from "./MunicipalitiesTable"
import RolesTable from "./RolesTable"
import PrivilegesTable from "./PrivilegesTable"
import UsersTable from "./UsersTable"
import Paper from "@mui/material/Paper"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

export default function BasicTabs() {
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const list_items = [
    {
      key: "users",
      text: "Users",
      component: <UsersTable />,
    },
    {
      key: "roles",
      text: "Roles",
      component: <RolesTable />,
    },
    {
      key: "privileges",
      text: "Privileges",
      component: <PrivilegesTable />,
    },
    {
      key: "institutions",
      text: "Institutions",
      component: <InstitutionTable />,
    },
    {
      key: "municipalities",
      text: "Municipalities",
      component: <MunicipalitiesTable />,
    },
    {
      key: "user_activity",
      text: "User Activity",
      component: <UserActivity />,
    },
  ]

  return (
    <Paper style={{ margin: "50px", minWidth: "900px" }}>
      <Box sx={{ borderBottom: 1, width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {list_items.map((list_item, index) => {
              return (
                <Tab label={list_item.text} {...a11yProps(index)} key={index} />
              )
            })}
          </Tabs>
        </Box>

        {list_items.map((list_item, index) => (
          <TabPanel value={value} index={index} key={index}>
            {list_item.component}
          </TabPanel>
        ))}
      </Box>
    </Paper>
  )
}
