import React from "react"
import ToolCard from "./ToolCard"
import bus from "../../assets/bus.jpg"
import dashboard from "../../assets/dashboard.jpg"
import fip from "../../assets/fip.jpg"
import csv_builder from "../../assets/csv_builder.jpg"
import scenario from "../../assets/scenario.jpg"
import school from "../../assets/school.jpg"
import freight from "../../assets/freight.jpg"
import mrio from "../../assets/mrio.jpg"
import Grid from "@mui/material/Grid"

const tool_details = [
  {
    title: "GTFS Platform",
    description:
      "Upload, create, edit and download public transport data in a standardized format used by Google maps.",
    image_name: bus,
    url: "/gtfs_platform",
    required_privilege: "gtfs_platform",
    repository: "gtfs-platform",
  },
  {
    title: "Scenario Planner",
    description:
      "Exploration. Application. Contemplation. A methodology to identify trends, datasets, and phenomena to include in setting up a scenario for simulation modelling.",
    image_name: scenario,
    url: "/scenario_development",
    required_privilege: "scenario_planner",
  },
  {
    title: "Dashboard",
    description:
      "Analyse important indicators of a scenario on charts and compare indicators between scenarios.",
    image_name: dashboard,
    url: "/urban_dashboard",
    required_privilege: "urban_dashboard",
    repository: "urban-dashboard",
  },
  {
    title: "Social Facilies Tool",
    description:
      "View current supply/demand profiles for various metros and determine impacts of facility changes to the supply/demand profiles.",
    image_name: school,
    url: "/facility_planning/app/",
    required_privilege: "social_facilities",
    repository: "facility-location",
  },
  {
    title: "Freight and Robustness",
    description:
      "Determine optimal distribution routes with current and future data. Determine robust locations for facilities for a given planning horizon.",
    image_name: freight,
    url: "/freight",
    required_privilege: "freight",
    repository: "freight-and-robustness",
  },
  {
    title: "Functional Integration Platform",
    description:
      "The Functional Integration Platform (FIP) addresses development priorities and spatial transformation by coordination of interventions to bring about impact and outcomes within specific areas.",
    image_name: fip,
    url: "/functional_integration",
    required_privilege: null,
    repository: "freight-and-robustness",
  },
  {
    title: "CSV Builder",
    description:
      "The CSV Builder provides a user friendly interface for creating inputs files of the UrbanSim model. The CSV builder ensures that all the data is in the correct format required by the model.",
    image_name: csv_builder,
    url: "/csv_builder",
    required_privilege: "csv_builder",
    repository: "csv-builder",
  },
  {
    title: "District Development Model",
    description:
      "The District Development Model (DDM) Simulator platform is a decision support tool, which will model the impact likely to be realised in a particular district/municipality through different intervention commitments.",
    image_name: mrio,
    url: "/MRIO",
    required_privilege: "mrio",
    repository: "mrio",
  },
]

export default function Landing(props) {
  const { privileges } = props

  return (
    <Grid container spacing={2} style={{ padding: 10 }}>
      {tool_details.map((tool_detail, index) => {
        return (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={index}>
            <ToolCard
              key={index}
              title={tool_detail.title}
              description={tool_detail.description}
              image_name={tool_detail.image_name}
              version={tool_detail.version}
              href={tool_detail.url}
              repository={tool_detail.repository}
              enabled={
                tool_detail.required_privilege
                  ? privileges.includes(tool_detail.required_privilege)
                  : true
              }
            />
          </Grid>
        )
      })}
    </Grid>
  )
}
