import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@mui/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import TableHead from '@mui/material/TableHead'
import DeleteIcon from '@mui/icons-material/Delete'
import Button from '@mui/material/Button'
import EditIcon from '@mui/icons-material/Edit'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import axios from 'axios'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'

const config = require('../../config.json')

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
})

function TablePaginationActions(props) {
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}

function ConfirmDelete(props) {
  const { openDialog, setOpenConfirmDelete, currentRow, set_institutions, setViewOnDelete } = props
  const { institution_pk } = currentRow
  const [error, set_error] = React.useState(null)

  const handleClose = () => {
    set_error(undefined)
    props.setOpenConfirmDelete(false)
  }

  const delete_institution = async () => {
    try {
      const { data } = await axios.delete(config.api_base_url + `/user_management/institution/${institution_pk}`)
      set_institutions((current_institutions) => {
        let updated_institutions = [...current_institutions]
        updated_institutions = updated_institutions.filter((table_row) => {
          return table_row.institution_pk !== data.institution_pk
        })
        return updated_institutions
      })
      setViewOnDelete()
      setOpenConfirmDelete(false)
    } catch (error) {
      set_error('Institution could not be deleted.')
    }
  }

  const info_items = [
    { label: 'Name', key: 'institution_name' },
    { label: 'Department', key: 'department_name' },
  ]
  return (
    <Dialog open={openDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Delete Institution</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to delete this entry?</DialogContentText>

        <Grid container spacing={0}>
          {info_items.map((item, index) => {
            return (
              <Fragment key={index}>
                <Grid item xs={6}>
                  <Typography>{item.label}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{currentRow[item.key]}</Typography>
                </Grid>
              </Fragment>
            )
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            delete_institution()
          }}
          color="primary"
        >
          Delete Entry
        </Button>
      </DialogActions>
      {error ? <Alert severity="error">{error}</Alert> : null}
    </Dialog>
  )
}

function EditDialog(props) {
  const { openDialog, setOpenEditDialog, currentRow, set_institutions, setCurrentRow } = props

  const handleClose = () => {
    setOpenEditDialog(false)
  }

  const edit_institution = async () => {
    try {
      const response = await axios.put(config.api_base_url + '/user_management/institution', currentRow)

      set_institutions((current_institutions) => {
        let updated_institutions = [...current_institutions]
        const elementsIndex = updated_institutions.findIndex(
          (table_row) => table_row.institution_pk === response.data.institution_pk
        )
        updated_institutions[elementsIndex] = response.data
        return updated_institutions
      })

      handleClose()
    } catch (error) {
      console.log(error)
    }
  }

  const handleValueChange = (event) => {
    const name = event.target.name
    const new_value = event.target.value
    var new_state = {
      ...currentRow,
      [name]: new_value,
    }
    setCurrentRow(new_state)
  }

  return (
    <Dialog open={openDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Update Institution</DialogTitle>
      <DialogContent>
        <DialogContentText>Update institution information.</DialogContentText>

        <TextField
          margin="dense"
          variant="standard"
          fullWidth
          name="institution_name"
          label="Institution Name"
          type="text"
          value={currentRow.institution_name}
          onChange={handleValueChange}
        />

        <TextField
          margin="dense"
          variant="standard"
          name="department_name"
          label="Department Name"
          type="text"
          multiline
          fullWidth
          value={currentRow.department_name}
          onChange={handleValueChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={edit_institution} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function NewDialog(props) {
  const { openNewDialog, setOpenNewDialog, set_institutions, setViewOnCreate } = props

  const initial_form_data = {
    institution_name: '',
    department_name: '',
  }
  const [formData, setFormData] = React.useState(initial_form_data)

  const handleValueChange = (event) => {
    const name = event.target.name
    const new_value = event.target.value
    var new_state = {
      ...formData,
      [name]: new_value,
    }
    setFormData(new_state)
  }

  const create_institution = async () => {
    try {
      const response = await axios.post(config.api_base_url + `/user_management/institution`, formData)
      set_institutions((current_institutions) => {
        let updated_institutions = [...current_institutions]
        updated_institutions.push(response.data)
        return updated_institutions
      })
      setFormData(initial_form_data)
      setOpenNewDialog(false)
      setViewOnCreate()
    } catch (error) {
      console.log(error)
    }
  }

  const handleClose = () => {
    setOpenNewDialog(false)
  }

  return (
    <Dialog open={openNewDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">New Institution</DialogTitle>
      <DialogContent>
        <DialogContentText>Create a new Institution Entry.</DialogContentText>

        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          name="institution_name"
          label="Institution Name"
          type="text"
          value={formData.institution_name}
          onChange={handleValueChange}
        />

        <TextField
          name="department_name"
          label="Department Name"
          type="text"
          margin="dense"
          variant="standard"
          multiline
          fullWidth
          value={formData.department_name}
          onChange={handleValueChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={create_institution} color="primary">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default function EditInstitution() {
  const classes = useStyles2()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false)
  const [openEditDialog, setOpenEditDialog] = React.useState(false)
  const [openNewDialog, setOpenNewDialog] = React.useState(false)
  const [currentRow, setCurrentRow] = React.useState({})
  const [institutions, set_institutions] = React.useState([])

  useEffect(() => {
    axios.get(config.api_base_url + '/user_management/institution/all_institutions').then(({ data }) => {
      set_institutions(data)
    })
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value))
    setPage(0)
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, institutions.length - page * rowsPerPage)

  const setViewOnCreate = () => {
    const last_page = Math.ceil(institutions.length / rowsPerPage) - 1
    setPage(last_page)
  }

  const setViewOnDelete = () => {
    const new_number_rows = institutions.length - 1
    const num_pages = Math.ceil(institutions.length / rowsPerPage) - 1
    if (new_number_rows % rowsPerPage === 0 && num_pages === page) {
      setPage(Math.max(num_pages - 1, 0))
    }
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Institution</b>
              </TableCell>
              <TableCell>
                <b>Department</b>
              </TableCell>
              <TableCell align="center">
                <b>Update</b>
              </TableCell>
              <TableCell align="center">
                <b>Delete</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? institutions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : institutions
            ).map((institution, index) => (
              <TableRow key={index}>
                <TableCell>{institution.institution_name}</TableCell>
                <TableCell>{institution.department_name}</TableCell>
                <TableCell align="center" padding="none">
                  <Button
                    onClick={() => {
                      setCurrentRow(institution)
                      setOpenEditDialog(true)
                    }}
                  >
                    <EditIcon />
                  </Button>
                </TableCell>
                <TableCell align="center" padding="none">
                  <Button
                    onClick={() => {
                      setCurrentRow(institution)
                      setOpenConfirmDelete(true)
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 52 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setOpenNewDialog(true)
                  }}
                >
                  Create Institution
                </Button>
              </TableCell>

              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                colSpan={8}
                count={institutions.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <NewDialog
        openNewDialog={openNewDialog}
        setOpenNewDialog={setOpenNewDialog}
        set_institutions={set_institutions}
        setViewOnCreate={setViewOnCreate}
      />
      <ConfirmDelete
        openDialog={openConfirmDelete}
        setOpenConfirmDelete={setOpenConfirmDelete}
        currentRow={currentRow}
        set_institutions={set_institutions}
        setViewOnDelete={setViewOnDelete}
      />
      <EditDialog
        openDialog={openEditDialog}
        setOpenEditDialog={setOpenEditDialog}
        currentRow={currentRow}
        set_institutions={set_institutions}
        setCurrentRow={setCurrentRow}
      />
    </div>
  )
}
