import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import axios from 'axios'
import background_blur from '../../assets/background_blur.jpg'
const config = require('../../config.json')

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100%',
    overflow: 'hidden',
    margin: '0px',
  },
  image: {
    backgroundImage: `url(${background_blur})`,
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '1px 0',
  },
  login: {
    width: '300px',
    margin: '40px auto',
    padding: '25px',
    backgroundColor: 'rgba(255, 255, 255, .8)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: '250px',
    margin: 'auto',
    display: 'block',
  },
  space: {
    margin: '25px',
  },
}))

export default function ChangePassword(props) {
  const classes = useStyles()
  const [password, set_passowrd] = useState('')
  const [password_check, set_passowrd_check] = useState('')
  const [messages, set_messages] = useState([])

  const validation = () => {
    let errors = []
    if (password.length < 5) {
      errors.push('Password is too short.')
    }
    if (password !== password_check) {
      errors.push('Passwords do not match.')
    }
    return errors
  }

  const change_password = async () => {
    const errors = validation()
    if (errors.length === 0) {
      try {
        await axios.post(config.api_base_url + '/user_management/user/change_password', {
          user_password: password,
        })
        set_messages([{ type: 'success', text: 'Password updated successfully' }])
      } catch (error) {
        set_messages([{ type: 'error', text: 'An error occurred' }])
        console.log(error)
      }
    } else {
      set_messages(
        errors.map((error) => {
          return {
            type: 'error',
            text: error,
          }
        })
      )
    }
  }

  return (
    <div className={classes.login}>
      <Typography variant="h5" color="secondary" align="center" className={classes.space}>
        Change Password
      </Typography>
      <Typography align="center" color="secondary">
        Change your password on the Smart Places platform.
      </Typography>
      <TextField
        fullWidth
        margin="dense"
        variant="standard"
        label="New Password"
        type="password"
        value={password}
        onChange={(e) => {
          set_passowrd(e.target.value)
        }}
      />
      <TextField
        fullWidth
        margin="dense"
        variant="standard"
        label="Re-type New Password "
        type="password"
        value={password_check}
        onChange={(e) => {
          set_passowrd_check(e.target.value)
        }}
      />
      <Button fullWidth className={classes.space} onClick={change_password} color="primary">
        Change Password
      </Button>

      {messages.map((message, index) => {
        return (
          <Alert key={index} severity={message.type} style={{ margin: '2px' }}>
            {message.text}
          </Alert>
        )
      })}
    </div>
  )
}
