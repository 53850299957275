import { useState, useEffect } from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import axios from "axios"
import setHeaders from "../../utils/setHeaders"
import { useHistory } from "react-router-dom"
import MenuItem from "@mui/material/MenuItem"
import { Typography } from "@mui/material"
import Fade from "@mui/material/Fade"

const config = require("../../config.json")

const RegisterForm = (props) => {
  const { set_messages } = props

  const history = useHistory()
  const [form_data, set_form_data] = useState({
    user_name: "",
    user_password: "",
    user_email: "",
    institution_pk: "",
    municipality_pk: "",
    code: "",
  })
  const [institutions, set_institutions] = useState([])
  const [municipalities, set_municipalities] = useState([])
  const [show_code_form, set_show_code_form] = useState(false)

  useEffect(() => {
    axios
      .get(
        config.api_base_url + "/user_management/institution/all_institutions"
      )
      .then((response) => {
        set_institutions(response.data)
      })
  }, [])

  useEffect(() => {
    axios
      .get(config.api_base_url + "/user_management/municipality/all")
      .then((response) => {
        set_municipalities(response.data)
      })
  }, [])

  const handle_change = (e) => {
    set_form_data((form_data) => {
      const value = e.target.value
      const name = e.target.name
      let new_form_data = { ...form_data }
      new_form_data[name] = value
      return new_form_data
    })
  }

  const request_code = async (event) => {
    event.preventDefault()
    set_messages([])
    try {
      await axios.post(
        config.api_base_url + "/user_management/user/register_create",
        form_data
      )
      set_messages([
        {
          severity: "success",
          message: "Confirmation code sent to your email.",
        },
      ])
      set_show_code_form(true)
    } catch (error) {
      if (error.response.data.errors) {
        set_messages(
          error.response.data.errors.map((error) => {
            return { severity: "error", message: error.msg }
          })
        )
      } else {
        set_messages([
          {
            severity: "error",
            message: "There was a problem contacting the server.",
          },
        ])
      }
    }
  }

  const send_code = async (event) => {
    set_messages([])
    try {
      const response = await axios.post(
        config.api_base_url + "/user_management/user/register_confirm",
        {
          code: form_data.code,
        }
      )
      localStorage.setItem("token", response.data.token)
      setHeaders(localStorage.token)
      history.push("/authorised/landing")
    } catch (error) {
      if (error.response.data.errors) {
        set_messages(
          error.response.data.errors.map((error) => {
            return { severity: "error", message: error.msg }
          })
        )
      } else {
        set_messages([
          {
            severity: "error",
            message: "There was a problem contacting the server.",
          },
        ])
      }
    }
  }

  return (
    <div>
      <div>
        <Typography variant="h6" align="center" color="secondary" marginTop={1}>
          Register
        </Typography>
        <TextField
          margin="dense"
          variant="standard"
          required
          fullWidth
          label="Username"
          name="user_name"
          onChange={handle_change}
          value={form_data.user_name}
          autoFocus
        />
        <TextField
          margin="dense"
          variant="standard"
          required
          fullWidth
          label="Email Address"
          name="user_email"
          onChange={handle_change}
          value={form_data.user_email}
        />
        <TextField
          margin="dense"
          variant="standard"
          required
          fullWidth
          name="user_password"
          label="Password"
          type="password"
          onChange={handle_change}
          value={form_data.user_password}
        />
        <TextField
          label="Institution"
          required
          select
          fullWidth
          margin="dense"
          value={form_data.institution_pk}
          onChange={handle_change}
          name="institution_pk"
          variant="standard"
        >
          {institutions.map((institution) => (
            <MenuItem
              key={institution.institution_pk}
              value={institution.institution_pk}
            >
              {institution.institution_name} - {institution.department_name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Municipality"
          required
          select
          fullWidth
          margin="dense"
          value={form_data.municipality_pk}
          onChange={handle_change}
          name="municipality_pk"
          variant="standard"
        >
          {municipalities.map((municipality) => (
            <MenuItem
              key={municipality.municipality_pk}
              value={municipality.municipality_pk}
            >
              {municipality.municipality_name}
            </MenuItem>
          ))}
        </TextField>
        <Button
          onClick={request_code}
          fullWidth
          variant="contained"
          color="primary"
          style={{ marginTop: "5px" }}
          value="Submit"
        >
          Register
        </Button>
        <Typography
          style={{ cursor: "pointer" }}
          variant="caption"
          color="primary"
          onClick={() => {
            set_show_code_form((show_code_form) => !show_code_form)
          }}
        >
          already have a code?
        </Typography>
      </div>

      <div>
        <Fade in={show_code_form} unmountOnExit={true}>
          <div>
            <TextField
              margin="dense"
              variant="standard"
              required
              fullWidth
              label="Code"
              name="code"
              onChange={handle_change}
              value={form_data.code}
              autoFocus
            />
            <Button
              onClick={send_code}
              fullWidth
              variant="contained"
              color="primary"
              style={{ marginTop: "5px" }}
            >
              Send Code
            </Button>
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default RegisterForm
