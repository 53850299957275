import React, { useState } from "react"
import { makeStyles } from "@mui/styles"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { useHistory } from "react-router-dom"
import { useEffect } from "react"
import axios from "axios"
import Grow from "@mui/material/Grow"

const config = require("../../config.json")

const useStyles = makeStyles({
  root: {
    // width: 345,
    height: 360,
    position: "relative",
    // margin: '10px',
  },
  media: {
    height: 150,
  },
  bottom_left: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
    display: "tableCell",
    verticalAlign: "middle",
  },
  bottom_right: {
    position: "absolute",
    bottom: "0px",
    right: "0px",
  },
})

export default function ToolCard(props) {
  const { title, description, image_name, enabled, href, route, repository } =
    props
  const classes = useStyles()
  const history = useHistory()
  const [version, set_version] = useState("")

  useEffect(() => {
    if (repository) {
      axios
        .get(config.api_base_url + `/github/release/${repository}/latest`)
        .then((response) => {
          const { data } = response
          if (data.tag_name) {
            set_version(response.data.tag_name)
          }
        })
        .catch(() => {
          // console.clear()
        })
    }
  }, [repository])

  const navigate_route = () => {
    history.push(route)
  }

  return (
    <Card
      className={classes.root}
      style={{
        backgroundColor: !enabled ? "lightGrey" : null,
      }}
    >
      <CardMedia
        className={classes.media}
        image={image_name}
        style={{ filter: !enabled ? "grayscale(100%)" : null }}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {description}
        </Typography>
      </CardContent>
      {version ? (
        <CardActions className={classes.bottom_left}>
          <Grow in={version ? true : false}>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              style={{ float: "left" }}
            >
              {version}
            </Typography>
          </Grow>
        </CardActions>
      ) : null}
      <CardActions className={classes.bottom_right}>
        <Button
          href={href && enabled ? href : null}
          size="small"
          color="primary"
          onClick={route && enabled ? navigate_route : null}
          style={{
            color: !enabled ? "grey" : null,
          }}
        >
          Go to Tool
        </Button>
      </CardActions>
    </Card>
  )
}
