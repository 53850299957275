import './App.css'
import React from 'react'
import Login from './components/Authorisation'
import grey from '@mui/material/colors/grey'
import { Route, Switch, Redirect } from 'react-router-dom'
import setHeaders from './utils/setHeaders'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import jwt_decode from 'jwt-decode'
import { browserName, browserVersion } from 'react-device-detect'
import axios from 'axios'
import Authorised from './components/Authorised'

const theme = createTheme({
  palette: {
    primary: {
      main: '#01366a',
    },
    secondary: {
      main: grey[900],
    },
  },
})

if (localStorage.token) {
  setHeaders(localStorage.token)
}
if (browserName) {
  axios.defaults.headers.common['browser-name'] = browserName
}
if (browserVersion) {
  axios.defaults.headers.common['browser-version'] = browserVersion
}

const ProtectedRoute = ({ ...props }) => {
  const { privilige_name } = props
  let isEnabled = false
  let privileges
  const decoded = jwt_decode(localStorage.token)
  if (decoded) {
    privileges = decoded.privileges.map((privilege) => privilege.privilege_name)
    if (privileges.includes(privilige_name)) {
      isEnabled = true
    }
  }
  return isEnabled ? (
    <Route path={props.path} render={({ match }) => <props.Component privileges={privileges} match={match} />}></Route>
  ) : (
    <Redirect to="/" />
  )
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route path="/" component={Login} exact />
        <ProtectedRoute path="/authorised" Component={Authorised} privilige_name={'login'} />
      </Switch>
    </ThemeProvider>
  )
}

export default App
