import React, { useState } from 'react'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import Alert from '@mui/material/Alert'
import background from '../../assets/background.jpg'
import csir from '../../assets/csir.png'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import LockResetIcon from '@mui/icons-material/LockReset'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import LoginForm from './LoginForm'
import RegisterForm from './RegisterForm'
import ResetForm from './ResetForm'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${background})`,
    width: '100%',
    minHeight: 'calc( 100vh - 2px)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '1px 0',
  },
  login: {
    width: '300px',
    margin: '40px auto',
    padding: '25px',
    backgroundColor: 'rgba(255, 255, 255, .9)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: '250px',
    margin: 'auto',
    display: 'block',
  },
  avatar: {
    marginTop: '20px',
    backgroundColor: theme.palette.primary.main,
  },
  space: {
    margin: '10px',
  },
}))

export default function Login() {
  const classes = useStyles()

  const [messages, set_messages] = useState([])
  const [action, set_action] = useState('login')

  return (
    <div className={classes.root}>
      <div className={classes.login}>
        <img alt={'background'} src={csir} className={classes.logo} />
        <Typography variant="h5" color="secondary" align="center" className={classes.space}>
          Welcome to the Smart Places Platform.
        </Typography>
        <Typography align="center" color="secondary">
          Login/Register to view the available tools.
        </Typography>

        <ToggleButtonGroup
          value={action}
          exclusive
          onChange={(event, value) => {
            set_messages([])
            if (value !== null) {
              set_action(value)
            }
          }}
          style={{ marginTop: '10px' }}
        >
          <ToggleButton value="login">
            <LockOpenIcon color="primary" />
          </ToggleButton>
          <ToggleButton value="register">
            <PersonAddIcon color="primary" />
          </ToggleButton>
          <ToggleButton value="reset">
            <LockResetIcon color="primary" />
          </ToggleButton>
        </ToggleButtonGroup>

        {action === 'login' ? <LoginForm set_action={set_action} set_messages={set_messages} /> : null}
        {action === 'register' ? <RegisterForm set_messages={set_messages} /> : null}
        {action === 'reset' ? <ResetForm set_messages={set_messages} /> : null}

        {messages.map((message, index) => {
          return (
            <Alert key={index} severity={message.severity} style={{ margin: '2px' }}>
              {message.message}
            </Alert>
          )
        })}

        <Box mt={2}>
          <Typography variant="body2" color="secondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.csir.co.za/">
              CSIR
            </Link>{' '}
            {new Date().getFullYear()}
          </Typography>
        </Box>
      </div>
    </div>
  )
}
